import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Rate } from "rsuite";
import {
  useAddFavouriteMutation,
  useGetFavouritesQuery,
} from "../../../app/services/favourite";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { loginAsync } from "../../../app/authRedux/customerSlice";
import { ProductDetails } from "../../../types/interfaces/productDetails";
import { dataz } from "../../../pages/customer/personalProfile/measurements/data";
import apiData from "../../../utils/axiosGlobal";
import { convertCurrency } from "../../../utils/helpers";
import SignIn from "../auth/signin";
import { Star } from "../icons";
import Rating from "../Rating";
import classes from "./CategoryCard.module.css";

interface CardI {
  data: ProductDetails;
  store?: boolean;
}

const CategoryCard = ({ data, store }: CardI) => {
  const { REACT_APP_BASEURL, REACT_APP_BASEUIMG } = process.env;
  const dispatch = useAppDispatch();

  const token = localStorage.getItem("token") || "";
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const maxLength = 15; // Set your maximum length

  const navigate = useNavigate();
  const [getData, setGetData] = useState<any>({
    ...data,
    cart: false,
    fav: false,
  });
  const [favData, setFavData] = useState<ProductDetails[]>([]);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  const getFavourites = async () => {
    if (token) {
      try {
        const res = await apiData.get(`${REACT_APP_BASEURL}/get-favorites`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "appication/json",
          },
        });
        if (!res.data.error) {
          setFavData(res?.data?.data?.data);
        }
      } catch (error: any) {
        // toast.error(error?.response?.data?.message)
      }
    }
  };

  useEffect(() => {
    getFavourites();
  }, [data]);

  useEffect(() => {
    const value = favData.find((item: ProductDetails) => item.id == data.id);
    if (value) {
      setGetData((prev: any) => ({ ...prev, fav: true }));
    } else {
      setGetData((prev: any) => ({ ...prev, fav: false }));
    }
    // return () => setGetData()
  }, [favData]);

  const handleLogin = async (data: { username: string; password: string }) => {
    setLoginLoading(true);
    const response: any = await dispatch(loginAsync(data));
    if (response.type == "auth/loginUser/rejected") {
      setLoginLoading(false);
    } else if (response.type == "auth/customerLogin/fulfilled") {
      if (response?.payload.error == false) {
        toast.success("Login Successful", {
          autoClose: 1500,
          hideProgressBar: true,
        });
        setLoginLoading(false);
        setShowLoginModal(false);
        setTimeout(() => {
          handleAddToCart();
        }, 100);
      } else if (response.payload) {
        if (response.payload.error == true) {
          setLoginLoading(false);
          toast.error(
            response?.payload?.message
              ? response?.payload?.message
              : "Error occured,Kindly try again",
            {
              autoClose: 1500,
              hideProgressBar: true,
            }
          );
        } else {
          setLoginLoading(false);
          toast.error(
            response?.payload?.errors
              ? response?.payload?.errors?.confirmation[0]
              : "Error occured,Kindly try again",
            {
              autoClose: 1500,
              hideProgressBar: true,
            }
          );
        }
        setLoginLoading(false);
      }
    }
  };

  const handleAddToCart = async () => {
    if (token) {
      if (getData.fav) {
        //remove favourites
        try {
          const res = await apiData.delete(
            `${REACT_APP_BASEURL}/remove-favorite/${data.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "appication/json",
              },
            }
          );

          if (res.data.data) {
            toast.success("Favourite successfully removed", {
              autoClose: 1500,
              hideProgressBar: true,
            });
            getFavourites();
          }
        } catch (error) {
          toast.error("Error occured,Kindly try again", {
            autoClose: 1500,
            hideProgressBar: true,
          });
        }
      } else {
        try {
          const res = await apiData.post(
            `${REACT_APP_BASEURL}/add-to-favorites/${data.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "appication/json",
              },
            }
          );
          if (res.data.data) {
            toast.success("Favourite successfully added", {
              autoClose: 1500,
              hideProgressBar: true,
            });
            getFavourites();
          }
        } catch (error) {
          toast.error("Error occured,Kindly try again", {
            autoClose: 1500,
            hideProgressBar: true,
          });
        }
      }
    } else {
      // setShowAddModal(true)
      setShowLoginModal(true);
    }
  };

  const handleAddToFav = async () => {
    setGetData((prev: any) => ({ ...prev, cart: !prev.cart }));
  };

  const navigateToCategory = () => {
    navigate(`/item/${data?.slugable?.key}`, { replace: false });
  };

  const handleStoreClick = (event: any) => {
    event.stopPropagation(); // Prevent the event from bubbling up
    const name = data?.store.name.split(" ").join("-");
    navigate(`/store/${data?.store?.slugable?.key}`);
  };

  const preventProp = (event: any) => {
    event.stopPropagation();
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigateToCategory()}
        className={classes.card}
      >
        <div className={classes.img_wrap}>
          <img
            role="presentation"
            loading="lazy"
            src={`${REACT_APP_BASEUIMG}/${data.image}`}
            alt=""
          />
        </div>
        <div className={classes.text}>
          <div
            className="m-0 p-0"
            style={{ minHeight: data?.reviews_avg ? "0rem" : "2rem" }}
          >
            {data?.reviews_avg && (
              <div className="m-0 p-0">
                <Rate
                  value={data?.reviews_avg}
                  style={{ transform: "translateY(.2rem)" }}
                  readOnly={true}
                  size="xs"
                  allowHalf
                />
              </div>
            )}
          </div>

          <strong>
            {data.name.length > maxLength
              ? `${data.name.substring(0, maxLength)}...`
              : data.name}
          </strong>

          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => handleStoreClick(e)}
          >
            <p>{data.store?.name}</p>
          </div>

          {data.sale_type >= 1 ? (
            <p className="text-decoration-line-through text-muted">
              {data.price}
            </p>
          ) : (
            //₦
            <p className="fw-bold">{data.price}</p>
          )}

          {data.sale_type >= 1 ? (
            <p className="fw-bold">{data.sale_price}</p>
          ) : (
            <p style={{ opacity: "0" }}>D</p>
          )}

          {/* {data.sale_type>=1?
          <p className="">{data.sale_price}</p>
          :
          <p style={{opacity:'0'}}>D</p>
        }
        
        {data.sale_type<1?<p className="">{data.price}</p> : <p className="text-decoration-line-through text-muted">{data.price}</p>} */}
        </div>
        <div className={classes.actions} onClick={(e) => preventProp(e)}>
          <span>
            {getData.fav ? (
              <i
                onClick={() => handleAddToCart()}
                className="fa fa-heart text-info"
              ></i>
            ) : (
              <i
                onClick={() => handleAddToCart()}
                className="fa fa-heart text-seccondary"
              ></i>
            )}
          </span>
          {/* <span>
          <i className="fa fa-magnifying-glass"></i>
        </span> */}
          <span>
            {/* {getData.cart?
          <i onClick={() => handleAddToFav()} className="bi bi-cart-fill text-bg-dark text-info px-3"></i>
            : <i onClick={() => handleAddToFav()} className="bi bi-cart-fill text-bg-dark text-dark px-3"></i>
            } */}
            <Link to={`/item/${data?.slugable?.key}`}>
              <i
                onClick={() => handleAddToFav()}
                className="bi bi-cart-fill text-bg-dark text-dark px-3"
              ></i>
            </Link>
          </span>
          {/* <span>
          <i
            onClick={() => alert("marked as comment")}
            className="fa fa-comment"
          ></i>
        </span> */}
        </div>

        {data.sale_type >= 1 && !store && (
          <div className={classes.discount}>
            -
            {Math.ceil(
              ((convertCurrency(data?.price) -
                convertCurrency(data?.sale_price)) /
                convertCurrency(data?.price)) *
                100
            )}
            %
          </div>
        )}
        {data.sale_type >= 1 && store && (
          <div className={classes.discount}>
            -
            {Math.ceil(
              ((convertCurrency(data?.price) -
                convertCurrency(data.sale_price)) /
                convertCurrency(data?.price)) *
                100
            )}
            %
          </div>
        )}
      </div>

      <Modal
        style={{ zIndex: "9999999999" }}
        className="rounded rounded-3"
        show={showLoginModal}
        backdrop="static"
        onHide={() => {
          setShowLoginModal(false);
          setLoginLoading(false);
        }}
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#F5F5F5" }}
          className="modal-header border-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className={classes.ModalBody}>
          <p className={classes.Notes}> Kindly Login to your Trybz account.</p>
          <div className="d-flex flex-column align-items-center">
            <SignIn
              handleSignin={(val: any) => handleLogin(val)}
              signinLoading={loginLoading}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CategoryCard;
