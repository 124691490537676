import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  useId,
} from "react";
import { Nav, Pagination as RPagination, Placeholder } from "rsuite";
import classes from "./Basic.module.css";
import classess from "../../LandingPage.module.css";

import cartItem from "../../../assets/images/cartItem.jpg";

import CategoryCard from "../../../component/element/categoryCard";
import { ProductDetails } from "../../../types/interfaces/productDetails";
import { getAllBespoke, getAllProducts } from "../../../app/services/product";
import NewFooter from "../../../component/element/footer/NewFooter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { switchTab } from "../../../app/authRedux/customerSlice";
import { useAppDispatch } from "../../../app/hooks";
import { homeTab } from "../../../types/enums";

const { REACT_APP_BLOG_BASEURL } = process.env;

// if (!REACT_APP_BLOG_BASEURL) {
//   console.error("REACT_APP_BLOG_BASEURL is not defined");
// } else {
//   console.log(`REACT_APP_BLOG_BASEURL: ${REACT_APP_BLOG_BASEURL}`);
// }

interface Post {
  id: number;
  name: string;
  slug: string;
  description: string;
  image: string;
  categories: { id: number; name: string; slug: string; description: string }[];
}

const Basic = () => {
  const [loadingProduct, setLoadingProduct] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const token = localStorage.getItem("token") || "";

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tabName, setTabName] = useState("");
  const [allProduct, setAllProduct] = useState<ProductDetails[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BLOG_BASEURL}/posts`);
        setPosts(response.data?.data);
      } catch (err) {
        console.error("Error fetching blog posts:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const prevScrollPos = parseInt(
      localStorage.getItem("scrollPosition") || "0"
    );
    window.scrollTo(0, prevScrollPos);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoadingProduct(true);
        // Check if products are already cached in local storage
        // const cachedProducts = localStorage.getItem("allProducts");
        // if (cachedProducts) {
        //   const res = await getAllProducts(token, 1, 1);
        // if (!res.data.error) {
        //   const products = res?.data?.data[0];
        // if (products.total > JSON.parse(cachedProducts).length) {
        //   const res = await getAllProducts(token, 1, "all");
        // if (!res.data.error) {
        //   const products = res?.data?.data[0]?.data;
        //   localStorage.setItem("allProducts", JSON.stringify(products));
        //   setAllProduct(products);
        //   setLoadingProduct(false);
        // }
        // } else {
        //   setAllProduct(JSON.parse(cachedProducts));
        //   setLoadingProduct(false);
        //   return;
        // }
        //   }
        // }

        let currentPage = 1;
        let allProducts:
          | any[]
          | ((prevState: ProductDetails[]) => ProductDetails[]) = [];

        // Continue fetching until all pages are fetched
        // while (true) {
        const res = await getAllProducts(token, currentPage, 20); //100
        console.log("got here");
        console.log(res);
        if (!res.data.error) {
          const products = res?.data?.data[0]?.data;
          allProducts = [...allProducts, ...products];
          //@ts-ignore
          // if (currentPage < res?.data?.data[0]?.last_page) {
          //   currentPage++;
          // } else {
          //   break;
          // }
        }
        // }

        // // Cache fetched products in local storage
        // localStorage.setItem("allProducts", JSON.stringify(allProducts));

        setAllProduct(allProducts);
        setLoadingProduct(false);
      } catch (error) {
        setLoadingProduct(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const storedTabName = localStorage.getItem("prevTabName");
    const storedPage = localStorage.getItem("prevPage");

    if (storedTabName && storedPage) {
      setTabName(storedTabName);
      setCurrentPage(parseInt(storedPage));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("prevTabName", tabName);
    localStorage.setItem("prevPage", currentPage.toString());
  }, [tabName, currentPage]);

  const paginateProducts = (
    items: any[],
    currentPage: number,
    itemsPerPage: number
  ) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items?.slice(startIndex, endIndex);
  };

  const paginatedProducts = paginateProducts(
    allProduct,
    currentPage,
    itemsPerPage
  );

  const handleChangePage = (dataKey: number) => {
    setCurrentPage(dataKey);
  };

  return (
    <>
      <div className={classes.banner}>
        <div className={classes.banner_main}>
          <div className={classes.banner_main_first}>
            <div className={classes.banner_main_first_content}>
              {/* <p>your new designs</p> */}
              <h1>Everyday Trybz</h1>
              <p>
                The treasure trove of unique finds that will elevate your style
                and set you apart EVERYDAY
              </p>
              <p onClick={() => navigate("/category/clothing-1")}>
                Explore all
              </p>
            </div>
            <a href="#best_seller">
              <div className={classes.scroll}>
                <svg
                  width="18"
                  height="11"
                  viewBox="0 0 18 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.55861 0.441386C1.2501 0.132871 0.7499 0.132871 0.441386 0.441386C0.132871 0.7499 0.132871 1.2501 0.441386 1.55861L1.55861 0.441386ZM9 9L8.44139 9.55861L9 10.1172L9.55861 9.55861L9 9ZM0.441386 1.55861L8.44139 9.55861L9.55861 8.44139L1.55861 0.441386L0.441386 1.55861ZM9.55861 9.55861L17.5586 1.55861L16.4414 0.441386L8.44139 8.44139L9.55861 9.55861Z"
                    fill="white"
                  ></path>
                </svg>
                <p>scroll</p>
              </div>
            </a>
          </div>
          <div className={classes.banner_main_second}>
            <img
              // src={ladyRight}
              src={
                "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/everyday/trybz-ca-everyday-landing-page.jpg"
              }
              alt="standing lady banner"
              height="100%"
              width="100%"
              role="presentation"
              loading="lazy"
              className={classes.best_seller_image_main}
            />
          </div>
        </div>
        <div className={classes.best_seller}>
          <p id="best_seller">Best Seller</p>
          <div className={classes.best_seller_content}>
            <div className={classes.best_seller_content_first}>
              <img
                src={
                  "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/everyday/trybz-ca-everyday-jumpsuit.jpg"
                }
                alt="lady in suit"
                className={classes.best_seller_image_main}
                role="presentation"
                loading="lazy"
              />
              <p className={classes.best_seller_content_first_heading}>
                Jumpsuits
              </p>
              <p className={classes.best_seller_content_first_text}>
                Every item embodies elegance, reflecting timeless style and
                exquisite craftsmanship
              </p>
              <button
                onClick={() => navigate("/category/jumpsuits")}
                className={`${classes.best_seller_content_first_button} ${classes.button_main}`}
              >
                Explore all
                <img src={cartItem} />
              </button>
            </div>
            <div className={classes.best_seller_content_second}>
              <div className={classes.best_seller_content_second_first}>
                <div className={classes.best_seller_content_second_first_1}>
                  <img
                    src={
                      "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/everyday/trybz-ca-everyday-dress.jpg"
                    }
                    className={classes.best_seller_image_1}
                    alt="women group"
                    role="presentation"
                    loading="lazy"
                  />
                  <p className={classes.best_seller_content_first_heading}>
                    Dresses
                  </p>
                  <p className={classes.best_seller_content_first_text}>
                    Created by talented African designers embodying cultural
                    richness
                  </p>
                  <button
                    onClick={() => navigate("/category/dresses")}
                    className={`${classes.best_seller_content_first_button} ${classes.button_main}`}
                  >
                    Shop all
                    <img src={cartItem} />
                  </button>
                </div>

                <div className={classes.best_seller_content_second_first_2}>
                  <div
                    className={classes.best_seller_content_second_background}
                  >
                    {" "}
                  </div>
                  <div className={classes.best_Seller_cover}>
                    <h6
                      className={
                        classes.best_seller_content_first_heading_tranparent
                      }
                    >
                      BouBou &amp; Kimono
                    </h6>
                    <p className={classes.best_seller_content_first_text}>
                      Unique patterns and exquisite creations from the
                      motherland
                    </p>
                    <button
                      onClick={() => navigate("/category/boubou")}
                      className={`${classes.best_seller_content_first_button_transparent} ${classes.button_background_transparent}`}
                    >
                      See More
                      <img src={cartItem} />
                    </button>
                  </div>
                </div>
              </div>

              <div className={classes.best_seller_content_second_2}>
                <img
                  // src={ladyInSuit}
                  src={
                    "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/everyday/trybz-ca-everyday-2piece.jpg"
                  }
                  className={classes.best_seller_image_3}
                  alt="women group"
                  role="presentation"
                  loading="lazy"
                />
                <p className={classes.best_seller_content_first_heading}>
                  2 Piece
                </p>
                <p className={classes.best_seller_content_first_text}>
                  Summer take over with 2 piece outfits
                </p>
                <button
                  onClick={() => navigate("/category/2-piece-set")}
                  className={`${classes.best_seller_content_first_button} ${classes.button_main}`}
                >
                  Explore all
                  <img src={cartItem} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.recommendation}>
          <h1 className="text-center">Recommended For You </h1>
          <div>
            <div className={classes.wrapper}>
              <>
                {loadingProduct ? (
                  <div className={classes.Skeleton_padding}>
                    <Placeholder.Grid columns={4} rowMargin={20} active />
                  </div>
                ) : (
                  <>
                    <div className={classess.contents}>
                      {paginatedProducts &&
                        paginatedProducts?.map((data: any, index: number) => (
                          <Fragment key={index}>
                            <CategoryCard data={data} />
                          </Fragment>
                        ))}
                    </div>
                    {/* <div className="d-flex mt-5">
                      <RPagination
                        className="m-auto"
                        size="lg"
                        prev
                        next
                        first
                        last
                        ellipsis
                        maxButtons={5}
                        layout={["pager"]}
                        total={allProduct?.length}
                        limitOptions={[12]}
                        limit={8}
                        activePage={currentPage}
                        onChangePage={handleChangePage}
                      />
                    </div> */}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.blog}>
        <h2>Blog</h2>
        <p>
          Discover the latest trends, style inspirations, and insider tips in
          our fashion-forward blog section. Unleash your creativity and stay
          ahead of the fashion curve with curated articles, expert interviews,
          and exclusive insights. Whether you're a trendsetter or a fashion
          novice, our blog is your go-to destination for all things chic. Dive
          into a world of fashion exploration.
        </p>
        {/* <div className={classes.blog_wrapper}>
          {loading ? (
            <Placeholder.Grid columns={2} rowMargin={20} active />
          ) : (
            posts.map((post) => (
              <div key={post.id} className={classes.blog_wrapper_content}>
                <div className={classes.content_img}>
                  <img
                    src={post.image}
                    alt={post.name}
                    width="100%"
                    height="100%"
                    role="presentation"
                    loading="lazy"
                  />
                </div>
                <h6>{post.name}</h6>
                <p>{post.description}</p>
                <span>
                  <a
                    href={`https://blog.trybz.ca/${post.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </span>
              </div>
            ))
          )}
        </div> */}
        <div className={classes.blog_wrapper}>
          {loading ? (
            <Placeholder.Grid columns={2} rowMargin={20} active />
          ) : (
            posts.slice(0, 2).map((post) => (
              <div key={post.id} className={classes.blog_wrapper_content}>
                <div className={classes.content_img}>
                  <img
                    src={post.image}
                    alt={post.name}
                    width="100%"
                    height="100%"
                    role="presentation"
                    loading="lazy"
                  />
                </div>
                <h6>{post.name}</h6>
                <p>{post.description}</p>
                <span>
                  <a
                    href={`https://blog.trybz.ca/${post.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Basic;
