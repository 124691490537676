import { Navigate, Outlet } from "react-router-dom";



const ProtectedeRoutes = () => {

    let auth = localStorage.getItem("token");
    return(
        auth? <Outlet/> : Navigate({to:'/'})
    )

}


export default ProtectedeRoutes;