import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiReview, IIMeta, IPaginate2 } from "../../types/interfaces/common";
import { ProductDetails } from "../../types/interfaces/productDetails";
import { IReview } from "../../types/interfaces/review";
import apiData from "../../utils/axiosGlobal";
import { baseQuery } from "./common";

export const favouriteApi = createApi({
  reducerPath: "favorite",
  baseQuery: baseQuery,
  tagTypes: ["Favorites"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //Partial<IReview>
    getFavourites: builder.query<IApiResponse<IPaginate2<ProductDetails[]>>,void>({
        query: () => `/get-favorites`,
        providesTags: [{ type: "Favorites", id: "LIST" }],
      }),
      addFavourite: builder.mutation<IApiResponse<{count:number}>, {id:number}>({
        query: (body) => ({
          url: `/add-to-favorites/${body.id}`,
          method: "POST"
        }),
        invalidatesTags: ["Favorites"],
    }),
    deleteFavourite: builder.mutation<IApiResponse<{count:number}>, {id:number}>({
        query(data) {
          return {
            url: `/remove-favorite/${data.id}`,
            method: "DELETE"
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "Favorites", id: arg.id }, "Favorites"],
    }),
}),
});

export const addToFavourite = async(id:number,token:string) => {
   try{
    const res = await apiData.post(`${process.env.REACT_APP_BASEURL}/add-to-favorites/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "appication/json",
      }
    })
    return res.data
   }catch(error:any){
    return error.response
   }
 }

 export const getFavourite = async(token:string) => {
  if(token){
  try{
   const res = await apiData.get(`${process.env.REACT_APP_BASEURL}/get-favorites`,{
     headers: {
       Authorization: `Bearer ${token}`,
       Accept: "appication/json",
     }
   })
   return res.data
  }catch(error:any){
   return error.response
  }
}
}

export const deleteFavourite = async(id:number,token:string) => {
  try{
   const res = await apiData.delete(`${process.env.REACT_APP_BASEURL}/remove-favorite/${id}`,{
     headers: {
       Authorization: `Bearer ${token}`,
       Accept: "appication/json",
     }
   })
   return res.data
  }catch(error:any){
   return error.response
  }
}

export const { 
    useGetFavouritesQuery,
    useAddFavouriteMutation,
    useDeleteFavouriteMutation
} = favouriteApi;


