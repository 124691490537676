import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiResponse2, IPaginate, IPaginate3 } from "../../../types/interfaces/common";
import { IAddress } from "../../../types/interfaces/customerSettings/address";
import { IMeeasurement,IAllMeeasurements, IUserMeasurement } from "../../../types/interfaces/customerSettings/measurement";
import apiData from "../../../utils/axiosGlobal";
import { baseQuery } from "../common";

export const MeasurementApi = createApi({
  reducerPath: "measurement",
  baseQuery: baseQuery,
  tagTypes: ["Measurement"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getMeasurements: builder.query<IApiResponse<IPaginate3<IAllMeeasurements[]>>, void>({
      query: () => "/get-measurements",
      providesTags: [{ type: "Measurement", id: "LIST" }],
    }),
    getUserMeasurements: builder.query<IApiResponse2<IUserMeasurement>, void>({
      query: () => "/get-fittings",
      providesTags: [{ type: "Measurement", id: "LIST" }],
    }),
    addfittings: builder.mutation<IMeeasurement, Partial<IMeeasurement>>({
        query: (body) => ({
          url: `/add-fitting`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Measurement"],
    }),
    editFittings: builder.mutation<IMeeasurement, Partial<IMeeasurement>>({
      query: (body) => ({
        url: `/edit-fitting/${body.id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Measurement"],
  }),
    updateAddress: builder.mutation<IMeeasurement, Partial<IMeeasurement>>({
        query(data) {
          return {
            url: `/edit-fitting/${data.id}`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: (result, error, arg) => [{ type: "Measurement", id: arg.id }, "Measurement"],
    }),
    
  }),
});

export const { 
  useGetMeasurementsQuery,
  useGetUserMeasurementsQuery,
  useEditFittingsMutation,
  useAddfittingsMutation,
  useUpdateAddressMutation
} = MeasurementApi;