import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customerState } from "../types";
import { RootState, AppThunk } from "../store";
import axios from "axios";
import apiData from "../../utils/axiosGlobal";
import apiDataz from "../../utils/axios";
import { getCurrentCurrency } from "../services/currency";
import { homeTab } from "../../types/enums";
const { REACT_APP_BASEURL } = process.env;

const token = localStorage.getItem('token')

const initialState: customerState = {
  type: "customer",
  isLoading: false,
  isAuthenticated: false,
  timeout: false,
  cartCount:0,
  id: "",
  username: "",
  address: "",
  email: "",
  token: "",
  authError: "",
  profileUpdate:false,
  showProducts:false,
  currentTab:homeTab.basic,
  currency:{
    code:""
  }
};


async function customerReg(userObj: object){
  try {
    const response = await axios.post(`${REACT_APP_BASEURL}/register`, userObj);
    return response.data;
  } catch (error: any) {
    return error.response?.data.message;
    // return error.response.statusText
  }
};


async function customerLogin(loginInfo: any) {
  try {
    const response = await axios.post(`${REACT_APP_BASEURL}/login`, loginInfo);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  };  
}


async function getDefaultCurrency(token:string) {
  try{
    const res = await getCurrentCurrency(token);
    return res?.data?.title;   
  }catch(error){
  }
};

export const registerAsync = createAsyncThunk("auth/customerReg", async (regInfo: any) => {
  const response = await customerReg(regInfo);
  return response;
});


export const loginAsync = createAsyncThunk("auth/customerLogin", async (loginInfo: any) => {
  const response = await customerLogin(loginInfo);
  return response;
});



export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getAuthentication: (state,action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    getCartCount: (state,action) => {
      return {
        ...state,
        cartCount: action.payload,
      };
    },
    getProfileUpdate: (state,action) => {
      return {
        ...state,
        profileUpdate: action.payload,
      };
    },
    switchTab: (state,action) => {
      return {
        ...state,
        currentTab: action.payload,
      };
    },
    showProductOrders: (state,action) => {
      return {
        ...state,
        showProducts: action.payload,
      };
    },
    timeoutFunc: (state, action) => {
      return {
        ...state,
        timeout: action.payload.payload,
      };
    },
    currencyChange: (state,action) => {
      return {
        ...state,
        currency: {
          code:action.payload.name
        }
      };
    },
    loguserOut: (state) => {
      return {
        ...state,
        isAuthenticated: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAsync.fulfilled, (state, action: any) => {
        if (action?.payload?.payload?.token) {
          const { fullname, hospitalId,address, phoneNumber, email } = action.payload?.payload?.user;
          const { civisHospitalId,name,country } = action.payload?.payload?.hospital;
          localStorage.setItem("email", email);
          localStorage.setItem("userId", action.payload.payload.user.id);
          localStorage.setItem("token", action?.payload?.payload?.token);
          const data = { hospitalId,address, phoneNumber, fullname, email,name, civisHospitalId,country };
          localStorage.setItem("user_info", JSON.stringify(data));
          return {
            ...state,
            fullname,
            hospitalId,
            address,
            name,
            isLoading: false,
            // isAuthenticated: true,
          };
        } else {
          return {
            ...state,
            isLoading: false,
            authError: action.payload?.message,
          };
        }
      })
      .addCase(registerAsync.rejected, (state, action: any) => {
        return {
          ...state,
          isLoading: false,
          authError: "Network Error",
        };
      })


      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        if (action?.payload?.data?.status=="success" && action?.payload?.data?.token ) {
          const { name, email, username,phone,id } = action.payload?.data?.user;
        //   const { civisHospitalId, name,country } = action.payload?.payload?.hospital;
          localStorage.setItem("token", action?.payload?.data?.token);
          localStorage.setItem("userId", action.payload?.data?.user.id);
        //   const data = { hospitalId, address, email, phoneNumber, fullname, civisHospitalId, name,firstName,lastName,middleName,title,country  };
        const data =  { name, email, username,phone };  
        localStorage.setItem("user_info", JSON.stringify(data));
          return {
            ...state,
            id,
            email,
            isLoading: false,
            isAuthenticated: true,
          };
        } else {
          return {
            ...state,
            isLoading: false,
            authError: action.payload?.message,
          };
        }
      })
      .addCase(loginAsync.rejected, (state, action: any) => {
        return {
          ...state,
          isLoading: false,
          authError: "Network Error",
        };
      })
  },
});

export const {loguserOut,getAuthentication,getCartCount,timeoutFunc,showProductOrders,currencyChange,getProfileUpdate,switchTab } = authSlice.actions;

export const selectStateValues = (state: RootState) => state.auth;

export default authSlice.reducer;


