const { REACT_APP_DOMAIN,REACT_APP_SUBDOMAIN } = process.env;


export const getTotalQuantity = (data: { content: { qty: string }[] }): number => {
    let totalQty = 0;
  
    data?.content?.forEach((item) => {
      const qty = parseInt(item.qty);
      if (!isNaN(qty)) {
        totalQty += qty;
      }
    });
  
    return totalQty;
  };


  export const extractCheckoutToken = (data:string) => {
   const extractedValue = data?.substring(data.lastIndexOf("/") + 1)    
   return extractedValue
  }

  export const convertedString = (value:string) => {
    const convertedString = value?.replace(/\//g, '/');
    return convertedString;
  }



  export const ClearCurrentCookie = () => {
    const cookies = [REACT_APP_DOMAIN,REACT_APP_SUBDOMAIN]

    for (let i = 0; i < cookies.length; i++) {
      document.cookie = `${cookies[i]}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }
  };

  export function convertCurrency(currencyString:string) {
    // Remove the currency symbol, if present
    const numericString = currencyString?.replace(/[$€₦]/g, '');
  
    // Parse the numeric string to a float or integer
    const numericValue = parseFloat(numericString); // Use parseFloat for decimal values or parseInt for integers
  
    return numericValue;
  }

  export function calculateTotal(finalPrice:any, couponDiscountAmount:any, promotionDiscountAmount:any, shippingAmount:any) {
    // Extract currency symbol from finalPrice
    const currency:string = finalPrice?.match(/[^\d.,]/g)?.join('')?.trim();
    
    const parseCurrency = (value:string) => {
      return parseFloat(value?.replace(currency, '')?.trim());
    };
  
    finalPrice = parseCurrency(finalPrice);
    couponDiscountAmount = parseCurrency(couponDiscountAmount?.toString());
    promotionDiscountAmount = parseCurrency(promotionDiscountAmount?.toString());
    shippingAmount = parseCurrency(shippingAmount?.toString());
  
    let totalAmount = finalPrice + shippingAmount;
  
    // Deduct coupon and promotion discounts
    totalAmount += couponDiscountAmount + promotionDiscountAmount;
  
    const formattedTotal = currency + totalAmount.toFixed(2); // Ensure 2 decimal places
  
    return formattedTotal;
  }

  export function limitToTenWords(text:any) {
    const words = text?.split(' ');
    if (words?.length > 20) {
      return words?.slice(0, 20).join(' ') +  '...'; // Limit to 10 words and add an ellipsis
    }
    return text;
  }
  
  export const calculateStarPercentages = (reviews:any[]) => {
    const starCounts = [0, 0, 0, 0, 0]; // Initialize counts for 1 to 5 stars
  
    reviews.forEach((review:any) => {
      starCounts[review?.star - 1]++; // Increment the count for the corresponding star
    });
  
    const totalReviews = reviews?.length;
  
    const starPercentages = starCounts?.map((count) =>
      // ((count / totalReviews) * 100).toFixed(2)
      Math.round((count / totalReviews) * 100)

    );
  
    return starPercentages?.reverse();
  };

  export function removeDuplicateSuffixes(originalURL:string,cart:boolean):string {
    const parts: string[] = originalURL?.split('-'); // Split the URL by the dash character '-'
    parts?.pop(); 
    const fileExtension: string = originalURL?.slice(cart?-4:-3)!; // Extract the file extension

  
    const dimensions: string[] = [];
  
    // Iterate through parts in reverse to find and remove the last dimension occurrence
    for (let i = parts?.length - 1; i >= 0; i--) {
      const part = parts[i];
      if (part?.match(/\d{1,4}x\d{1,4}/)) {
        if (!dimensions?.includes(part)) {
          dimensions?.push(part); // Store unique dimensions
        } else {
          parts?.splice(i, 1); // Remove duplicate dimension
        }
      }
    }
  
    const modifiedURL: string = parts?.join('-') + "." + fileExtension; // Reconstruct the modified URL  

    // Remove duplicate dot
    const cleanedUrl = modifiedURL?.replace(/\.+/g, '.');

    
  return cleanedUrl;
    
  }

  export function containsBase64Image(screen: string): boolean {
    const regex = /^data:image\/[a-zA-Z]*;base64,([^\"]*)/;
    return regex.test(screen);
  }