import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../../types/interfaces/common";
import { IMe, IReset } from "../../../types/interfaces/customerSettings/me";
import { baseQuery } from "../common";

export const meApi = createApi({
  reducerPath: "me",
  baseQuery: baseQuery,
  tagTypes: ["Me"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getUserDetails: builder.query<IApiResponse<IPaginate<IMe>>, void>({
      query: () => "/me",
      providesTags: [{ type: "Me", id: "LIST" }],
    }),
    upload: builder.mutation<IApiResponse<Partial<IMe>>, FormData>({
      query: (body) => ({
        url: `/update/avatar`,
        method: "POST",
        body,
      }),  
      invalidatesTags: ["Me"],
    }),
    forgetPassword: builder.mutation<string, {email:string}>({
      query: (body) => ({
        url: `/password/forgot`,
        method: "POST",
        body,
      }),
    }),
    updatePassword: builder.mutation<any,{old_password:string,password:string} >({
      query: (body) => ({
        url: `update/password`,
        method: "PUT",
        body,
      }),
    }),
    resetPassword: builder.mutation<any,IReset>({
      query: (body) => ({
        url: `password/reset`,
        method: "POST",
        body,
      }),
    }),
})

})

export const { 
    useGetUserDetailsQuery,
    useUploadMutation,
    useForgetPasswordMutation,
    useUpdatePasswordMutation,
    useResetPasswordMutation
} = meApi;
