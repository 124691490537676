import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'rsuite/dist/rsuite.min.css';
import "rsuite/styles/index.less";
// import "rsuite/dist/rsuite.min.css";
import "./custom.less";
import { BrowserRouter } from 'react-router-dom';
// import "./custom.scss";

const container = document.getElementById("root")!;
const root = createRoot(container);
const tagManager = document.getElementById("googleTag");
let gmtScript:any = document.getElementById("gtm-script");
const gmtNoScript = document.getElementById("gtm-noscript");

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
    </BrowserRouter>

  // </React.StrictMode>
);



if(process.env.REACT_APP_ENV == "production"){
  // gmtScript.innerHTML = `<script> ${(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  // })(window,document,'script','dataLayer','GTM-NSS45T8W')} </script>`
  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
