import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import everyday from "../assets/images/African pattern 2.jpg";
import luxury from "../assets/images/African pattern 2.jpg";
import "./HomePage.css";
import { AnimatePresence, motion } from "framer-motion";
import { FaArrowRightLong } from "react-icons/fa6";
import logo from "../assets/images/logoT.svg";
import SideNav from "../component/sideNav";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { switchTab } from "../app/authRedux/customerSlice";
import { homeTab } from "../types/enums";
import SearchDrawer from "../component/element/searchBackdrop";
import { ProductDetails } from "../types/interfaces/productDetails";
import { getAllProducts } from "../app/services/product";

interface HoverContentProps {
  title: string;
  action: string;
  actionUrl: string;
  fields: { title: string,link:string }[];
  hover: string;
  tabName: string;
  // setHomeScreen: (screen: string) => void;
  // homeScreen: any;
  onMouseLeave: () => void;
  // showWidth: any;
  // setShowWidth: any;
}

const Overlay = ({ show }: { show: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: show ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 30,
        display: show ? "block" : "none",
      }}
    />
  );
};
export default function HomePage() {
  const token = localStorage.getItem("token") || "";

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [hover, setHover] = useState("none");
  const [showSideNav, setShowSideNav] = useState(false);
  const [allBespoke, setAllBespoke] = useState<ProductDetails[]>([]);


  useEffect(() => {
    getProducts()
  },[])

  const handleSearchItem = () => {
    navigate(`/search/${searchValue}`);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getProducts = async () => {
    let allBespokeProducts:
      | any[]
      | ((prevState: ProductDetails[]) => ProductDetails[]) = [];

    // Continue fetching until all pages are fetched
    const res = await getAllProducts(token, 1, 3);

    if (!res.data.error) {
      const bespokeProducts = res?.data?.data[0]?.data;
      allBespokeProducts = [...allBespokeProducts, ...bespokeProducts];
      setAllBespoke(allBespokeProducts);
    }
  };


  const getStyles = () => {
    let width;

    if (window.innerWidth <= 786) {
      width = "50%";
    } else {
      width = hover === "basic" ? "100%" : hover === "luxury" ? "0%" : "50%";
    }

    let backgroundImage = hover === "basic" ? "" : "";

    return {
      position: "relative",
      width: width,
      backgroundImage: backgroundImage,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      overflow: "hidden",
      transition: "all 0.3s",
      cursor: hover !== "none" ? "pointer" : "default",
    };
  };

  const getLuxuryStyles = () => {
    let width;

    if (window.innerWidth <= 786) {
      width = "50%";
    } else {
      width = hover === "luxury" ? "100%" : hover === "basic" ? "0%" : "50%";
    }

    let backgroundImage = hover === "luxury" ? "" : "";

    return {
      position: "relative",
      width: width,
      backgroundImage: backgroundImage,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      overflow: "hidden",
      transition: "all 0.3s",
      cursor: hover !== "none" ? "pointer" : "default",
    };
  };

  return (

    <>
      {showSideNav ? <Overlay show={showSideNav} /> : ""}

      <div className="" style={{ position: "relative" }}>
        <AnimatePresence>
          {showSideNav && (
            <motion.div
              key="sidenav"
              initial={{ opacity: 0, translateX: -100 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: -100 }}
              transition={{ duration: 0.1 }}
              className="sideNav"
            >
              <SideNav
                showSideNav={showSideNav}
                setShowSideNav={setShowSideNav}
              />
            </motion.div>
          )}
          <motion.main
            key="home"
            initial={{ opacity: 0, translateY: -100 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="home"
          >
            <div className="left">
              <div className="top">
                <div className="top-first">
                  {" "}
                  <img
                    src="/logo-white.png"
                    alt="trybz logo"
                    width={"100"}
                    height={"36"}
                  />
                  <div
                    onClick={() => {
                      setShowSideNav(true);
                    }}
                    className="cursor-pointer"
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_136_2030)">
                        <path
                          d="M3.75 12H20.25"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.75 6H20.25"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.75 18H20.25"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_136_2030">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="top-second">
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(true)}
                  >
                    <g clip-path="url(#clip0_136_2032)">
                      <path
                        d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.8037 15.8037L21.0003 21.0003"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_136_2032">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <a href="/cart">
                    <svg
                      width="42"
                      height="36"
                      viewBox="0 0 42 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_136_2033)">
                        <path
                          d="M22.875 21.25H13.7972C13.4459 21.2499 13.1058 21.1266 12.8361 20.9015C12.5665 20.6764 12.3844 20.3637 12.3216 20.0181L9.93 6.86594C9.8986 6.69313 9.80755 6.53681 9.67272 6.42425C9.53789 6.31169 9.36783 6.25003 9.19219 6.25H7.5"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.875 25C14.9105 25 15.75 24.1605 15.75 23.125C15.75 22.0895 14.9105 21.25 13.875 21.25C12.8395 21.25 12 22.0895 12 23.125C12 24.1605 12.8395 25 13.875 25Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.875 25C23.9105 25 24.75 24.1605 24.75 23.125C24.75 22.0895 23.9105 21.25 22.875 21.25C21.8395 21.25 21 22.0895 21 23.125C21 24.1605 21.8395 25 22.875 25Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.8641 17.5H23.6344C23.9857 17.4999 24.3258 17.3766 24.5954 17.1515C24.8651 16.9264 25.0472 16.6137 25.11 16.2681L26.25 10H10.5"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>

                      <defs>
                        <clipPath id="clip0_136_2033">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(6 4)"
                          />
                        </clipPath>
                        <clipPath id="clip1_136_2033">
                          <rect
                            x="22"
                            width="20"
                            height="20"
                            rx="10"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="left_div">
                <a href="/category/clothing" className="">
                  <span className="shop">SHOP ALL</span>
                </a>
                <p className="deals">
                  Get latest updates about new arrivals and discount deals
                </p>
                <input
                  className="input"
                  type="text"
                  placeholder="Enter email"
                />
              </div>
            </div>

            <div className="right">
              <div
                //@ts-ignore
                style={getStyles()}
                onMouseEnter={() => setHover("basic")}
                onMouseLeave={() => setHover("none")}
                // onTouchStart={() => setHover("basic")}
                // onTouchEnd={() => setHover("none")}
                // onClick={() => {
                //   if (window.innerWidth > 786) {
                //     setHover("none");
                //   }
                // }}
                className="item"
              >
                {hover === "none" && (
                  <HoverSubText
                    title="Everyday Trybz"
                    action={"BROWSE ALL"}
                    text=""
                  />
                )}
                {hover === "basic" && (
                  <HoverContent
                    title={"Everyday Trybz"}
                    action={""}
                    fields={[
                      { title: "Shirts",link:"/category/shirts-1" },
                      { title: "Shorts",link:"/category/shorts" },
                      { title: "Trousers",link:"/category/pants" },
                      { title: "Jacket and Coats",link:"/category/jackets" },
                      { title: "Tops",link:"/category/tops-1" },
                      { title: "Hoodie & Sweatshirts",link:"/category/hoodies-sweatshirts-1" },
                      { title: "Activewear",link:"/category/active-wear"  },
                      { title: "Native",link:"/category/traditional-attire-1"  },
                    ]}
                    actionUrl="/HomePageLayout"
                    hover={hover}
                    tabName="basic"
                    onMouseLeave={() => setHover("none")}
                  />
                )}
              </div>
              <div
                //@ts-ignore
                style={getLuxuryStyles()}
                onMouseEnter={() => setHover("luxury")}
                onMouseLeave={() => setHover("none")}
                // onTouchStart={() => setHover("luxury")}
                // onTouchEnd={() => setHover("none")}
                onClick={() => {
                  if (window.innerWidth > 786) {
                    setHover("none");
                  }
                }}
                className="item"
              >
                {hover === "none" && (
                  <HoverSubText
                    title=" Trybz Luxury"
                    action={"BROWSE ALL"}
                    text=""
                  />
                )}
                {hover === "luxury" && (
                  <HoverContent
                    title={"Trybz Luxury"}
                    action={""}
                    fields={[
                      { title: "Shirts",link:"/home" },
                      { title: "Shorts",link:"/home"  },
                      { title: "Trousers",link:"/home"  },
                      { title: "Jacket and Coats",link:"/home" },
                      { title: "Tops",link:"/home"  },
                      { title: "Hoodie & Sweatshirts",link:"/home"  },
                      { title: "Activewear",link:"/home"  },
                      { title: "Native",link:"/home"  },
                    ]}
                    actionUrl="/"
                    hover={hover}
                    tabName="luxury"
                    onMouseLeave={() => setHover("none")}
                  />
                )}{" "}
              </div>
            </div>
          </motion.main>
        </AnimatePresence>
      </div>

      <SearchDrawer 
        open={open}
        setOpen={(val:boolean) => setOpen(val)}
        searchValue={searchValue}
        handleSearchItem={()=>handleSearchItem()}
        handleInputChange={(e:ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
        allBespoke={allBespoke}
      />
      
    </>
  );
}

const HoverSubText = ({
  title,
  text,
  action,
}: {
  title: string;
  action: string;
  text: string;
}) => {
  return (
    <div className="subcontext">
      <div className="subcontext_first">
        <h4 className="">{title}</h4>

        <h6 className="">
          <span className=" underline underline-offset-8">{action}</span> {text}
        </h6>
      </div>
    </div>
  );
};

const HoverContent = ({
  title,
  hover,
  action,
  fields,
  actionUrl,
  tabName,
  onMouseLeave,
}: HoverContentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const handleClick = () => {
  //   if (title === "Everyday Trybz") {
  //     setHomeScreen("basic");
  //   } else if (title === "Trybz Luxury") {
  //     setHomeScreen("luxury");
  //   }

  //   console.log(title);
  // };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (hover) {
      timeout = setTimeout(() => {
        // setShowWidth(true);
      }, 500); // 0.5 second delay
    } else {
      // setShowWidth(false);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [hover]);
  // const onMouseLeave = () => {
  //   setShowWidth(false);
  // };

  // Define initial animation properties based on hover state
  const initialAnimation = hover === "basic" ? { y: -100 } : { x: "100%" };

  // Define exit animation properties based on hover state

  const exitAnimation = hover === "basic" ? { y: -100 } : { x: "100%" };

  const getHoverContentStyles = () => {
    let backgroundColor =
      hover === "basic" ? "#B72E5E" : hover === "luxury" ? "#a67c00" : "";
    let opacity = hover !== "none" ? 0.85 : 1;
    let right = hover === "luxury" ? 0 : "auto";

    return {
      backgroundColor: backgroundColor,
      opacity: opacity,
      right: right,
      position: "absolute",
      padding: "1rem", // Adjust this as necessary
      transition: "all 0.3s",
      fontSize:"10px"
    };
  };

  const hoverContentStyles = getHoverContentStyles();
  return (
    <>
      <motion.div
        initial={initialAnimation}
        animate={{ y: 0, x: 0 }}
        exit={exitAnimation}
        transition={{ duration: 0.5 }}
        className="hovercontent"
        onMouseLeave={onMouseLeave}
        //@ts-ignore
        style={hoverContentStyles}
      >
        <div className="hovercontent_first-div">
          {fields.map((item, index) => (
            <button
              style={{ backgroundColor: "transparent", textAlign: "left" }}
              onClick={() => {
                // navigate(`${item.link}`);
                navigate("/home");
                dispatch(
                  switchTab(
                    tabName === "basic" ? homeTab.basic : homeTab.luxury
                  )
                );
              }}
              className="hovercontent_links"
              key={index}
            >
              {item?.title}
            </button>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          <h1
            className="hovercontent_title"
            onClick={() => {
              navigate("/home");
              dispatch(
                switchTab(tabName == "basic" ? homeTab.basic : homeTab.luxury)
              );
            }}
          >
            {title}
            <FaArrowRightLong />
          </h1>
          <a
            role="button"
            href={actionUrl}
            className="underline underline-offset-8 hover:cursor-pointer transition-all"
          >
            {action}
          </a>
        </div>
      </motion.div>
    </>
  );
};
