import React from "react";
import { Input, InputGroup } from "rsuite";
import classes from "./footer.module.css";
import cropped from "../../../assets/images/cropped.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.MainWrapper}>
      <div className={classes.MainContent}>
        {/* <h2 className={classes.Logo}>Trybz</h2> */}
        <div className={classes.Logo} style={{ width: "12%" }}>
          <img src={cropped} width="100%" />
        </div>

        <div className={classes.ContentText}>
          <p>
            <Link
              className="text-decoration-none text-dark"
              to="https://blog.trybz.ca"
            >
              Blog
            </Link>
          </p>
          <p>
            <Link className="text-decoration-none text-dark" to="/about-us">
              About Us
            </Link>
          </p>
          <p>
            <Link className="text-decoration-none text-dark" to="/contact-us">
              Contact
            </Link>
          </p>
          <p>
            <Link
              className="text-decoration-none text-dark"
              to="/terms-and-condition"
            >
              Terms & Conditions
            </Link>
          </p>
        </div>

        <div className={classes.ContentSocial}>
          <Link
            to="https://www.facebook.com/profile.php?id=61554153436078"
            target="_blank"
            className={classes.Lg}
          >
            <i className="fa-brands fa-facebook-f"></i>
            <p>Facebook</p>
          </Link>

          <Link
            to="https://www.twitter.com/trybz_ca"
            target="_blank"
            className={classes.Lg}
          >
            <i className="fa-brands fa-twitter"></i>
            <p>Twitter</p>
          </Link>
          <Link
            to="https://www.instagram.com/trybz.ca/"
            target="_blank"
            className={classes.Lg}
          >
            <i className="fa-brands fa-instagram"></i>
            <p>Instagram</p>
          </Link>
          <Link
            to="https://www.pinterest.com/trybzca"
            target="_blank"
            className={classes.Lg}
          >
            <i className="fa-brands fa-pinterest-p"></i>
            <p>Pinterest</p>
          </Link>
        </div>

        <div className={classes.ContentSub}>
          <p className="mb-4">Subscribe to our newsletter</p>
          <div className={classes.Box}>
            <InputGroup>
              <Input
                className={"custom-input-number"}
                style={{
                  backgroundColor: "lightgrey",
                  border: "1px solid black",
                }}
                placeholder="Email Address"
              />
              <InputGroup.Button
                style={{ backgroundColor: "black", color: "whitesmoke" }}
              >
                Ok
              </InputGroup.Button>
            </InputGroup>
          </div>
        </div>

        <div className={classes.Add}>
          <p>26 Animashaun Street</p>
          <p className="mb-3">Surulere, Lagos.</p>
          <a href="mailto:info@trybz.ca">info@trybz.ca</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
