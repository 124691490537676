import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiReview, IIMeta } from "../../types/interfaces/common";
import { IReview } from "../../types/interfaces/review";
import { baseQuery } from "./common";

export const reviewApi = createApi({
  reducerPath: "review",
  baseQuery: baseQuery,
  tagTypes: ["Review"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //Partial<IReview>
    getReview: builder.query<IApiReview<IReview>, number>({
        query: (id) => `/get-reviews/${id}`,
        providesTags: [{ type: "Review", id: "LIST" }],
      }),
    addReview: builder.mutation<IReview, Partial<IReview>>({
        query: (body) => ({
          url: `/review-create`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Review"],
    })
}),
});

export const { 
    useGetReviewQuery,
    useAddReviewMutation
} = reviewApi;