import React, { useEffect, useState } from "react";
import { selectStateValues } from "../../../app/authRedux/customerSlice";
import { useAppSelector } from "../../../app/hooks";
import Basic from "../basic/Basic";
import Luxury from "../luxury/Luxury";
import classes from "./HomePageLanding.module.css";
import classess from "../../LandingPage.module.css";

import delivery from "../../../assets/icons/delivery.svg";
import ladyInGown from "../../../assets/images/ladyInGown.jpg";
import headSet from "../../../assets/icons/headSet.svg";
import infinity from "../../../assets/icons/infinity.svg";
import { AnimatePresence, motion } from "framer-motion";

const HomePageLanding = () => {
  const { currentTab } = useAppSelector(selectStateValues);

  return (
    <>
      <div className={classes.Wrapper}>
        {currentTab == 0 ? <Basic /> : <Luxury />}
      </div>

      <div className={classes.section_Banner}>
        <div className={`${classess.LandingBase} ${classes.info}`}>
          <div className={`${classess.CardsDisplay} ${classes.info_wrapper}`}>
            <div>
              <img src={headSet} className={classes.headSet} alt="icons" />
              <p className="mt-3">
                Enjoy round-the-clock assistance for seamless product listings,
                order management, and so on. We've got your back, empowering
                your brand to thrive in the world of fashion.
              </p>
            </div>

            <div>
              <img src={infinity} alt="icons" />
              <p className="mt-3">
                Our fashion marketplace offers exclusive pieces with a no-return
                policy. Every purchase is a commitment to your unique look. Shop
                boldly, wear proudly. Elevate your fashion experience today!
              </p>
            </div>

            <div>
              <img src={delivery} alt="icons" />
              <p className="mt-3">
                Step into style instantly! Our fashion marketplace guarantees
                lightning-fast delivery for your custom made clothes. Shop
                today, wear in no time. Swift, stylish, satisfaction delivered
                to your doorstep!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageLanding;
