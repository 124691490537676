import React, { ChangeEvent } from "react";
import classes from './styles.module.css'

interface ISearch {
    handleSearch: () => void,
    handleInputChange: (e:ChangeEvent<HTMLInputElement>) => void,
    placeholder:string,
    [key:string]: any,
    searchValue:string;
}
const Search = ({handleSearch,handleInputChange,placeholder,searchValue,...props}:ISearch) => {

    return(
        <>
            <div className="d-flex align-items-center">
                <input onChange={(e)=>handleInputChange(e)} className={classes.Container_search} placeholder={placeholder} />
                <button style={{backgroundColor:'transparent'}} disabled={searchValue?false:true} onClick={()=>handleSearch()}>
                    <i  style={{fontSize:'2.0rem',cursor:'pointer'}} className="fa fa-magnifying-glass"></i>
                </button>
            </div>
        </>
    )
}

export default Search

//:React.FC<any>