import React, { Component, ErrorInfo, ReactNode } from "react";
import Footer from "./element/footer/footer";
import cropped from "../assets/images/cropped.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import classes from "./styles.module.css";

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error
    // console.error("Error Boundary caught an error:", error, errorInfo);
    // You can also send error logs to a service like Sentry
    // SomeErrorLoggingService.logError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div className="vh-100">
          <Navbar
            key="lg"
            expand="lg"
            style={{ backgroundColor: "#F5F5F5" }}
            sticky="top"
          >
            <Container className={classes.containerNav}>
              <div className="align-self-start pt-4">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto"></Nav>
                </Navbar.Collapse>
              </div>
              <Navbar.Brand href="/" className={classes.logo}>
                <img src={cropped} width="100%" />
              </Navbar.Brand>
              <div className="align-self-start pt-4"></div>
            </Container>
          </Navbar>
          <div className=" text-center mt-5">
            <h1 className="header text-center fw-lighter display-4 mb-4">
              Something went wrong. Please try again.
            </h1>
            <button
              className="btn btn-dark btn-lg"
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </button>
          </div>
          <div style={{ marginTop: "55vh" }} className="">
            <Footer />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
