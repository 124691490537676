import React, { ChangeEvent, Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import { Drawer } from "rsuite";
import { ProductDetails } from '../../types/interfaces/productDetails';
import classes from "../newNav.module.css"
import Search from '../search';
import CategoryCard from './categoryCard';

interface ISearch {
open:boolean;
searchValue: string;
allBespoke: ProductDetails[];
setOpen:(type:boolean) => void;
handleSearchItem:() => void;
handleInputChange:(e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchDrawer = ({open,searchValue,allBespoke,setOpen,handleSearchItem,handleInputChange}:ISearch) => {

    const navigate = useNavigate();

return (

<Drawer size="xs" open={open} onClose={() => setOpen(false)}>
<Drawer.Body>
  <div className={classes.drawer}>
    <h6>search for products</h6>
    <div style={{ marginTop: "30px" }}>
      <Search
        handleSearch={() => handleSearchItem()}
        handleInputChange={(e) => handleInputChange(e)}
        placeholder="search"
        searchValue={searchValue}
      />
    </div>

    <div className={classes.drawer_trending}>
      <p>Trending</p>
      <div className={classes.drawer_trending_buttons}>
        <div className="d-flex justify-content-between aliign-items-center">
          <button
            onClick={() => {
              navigate("/category/shirts");
              setOpen(false);
            }}
          >
            {" "}
            Shirts
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => {
              navigate("/category/jumpsuits");
              setOpen(false);
            }}
          >
            {" "}
            Jumpsuit{" "}
          </button>
        </div>
        <div className="d-flex justify-content-between aliign-items-center">
          <button
            onClick={() => {
              navigate("/category/boubou");
              setOpen(false);
            }}
          >
            {" "}
            Boubou{" "}
          </button>
        </div>
        <div className="d-flex justify-content-between aliign-items-center">
          <button
            onClick={() => {
              navigate("/category/sneakers");
              setOpen(false);
            }}
          >
            {" "}
            Sneakers{" "}
          </button>
        </div>
        <div className="d-flex justify-content-between aliign-items-center">
          <button
            onClick={() => {
              navigate("/category/skirts");
              setOpen(false);
            }}
          >
            Skirt{" "}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div className={classes.drawer_trending}>
    <p>New Products</p>
    <>
      <div>
        {allBespoke &&
          allBespoke?.map((data: any, index: number) => (
            <div  onClick={() => setOpen(false)} key={index}>
              <CategoryCard data={data} />
            </div>
          ))}
      </div>
    </>
  </div>
</Drawer.Body>
</Drawer>

)


}

export default SearchDrawer;