import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiResponse2, IPaginate, IPaginate2 } from "../../types/interfaces/common";
import { IAddress } from "../../types/interfaces/customerSettings/address";
import api from "../../utils/api";
import { baseQuery } from "./common";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ProductDetails } from "../../types/interfaces/productDetails";
import { ICart, ICRes } from "../../types/interfaces/cart";


export const CartApi = createApi({
  reducerPath: "cart",
  baseQuery: baseQuery,
  tagTypes: ["Cart"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getCart: builder.query<IApiResponse<IPaginate2<ICart[]>>, void>({
      query: () => "/cart",
      providesTags: [{ type: "Cart", id: "LIST" }],
    }),
    addToCart: builder.mutation<{id:string,qty:string}, {id:string,qty:string}>({
      query: (body) => ({
        url: `/cart/add-to-cart`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const { 
    useGetCartQuery,
    useAddToCartMutation
} = CartApi;
