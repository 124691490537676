import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL, 
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem("token");
    headers.set('credentials', 'include');
    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
    return headers;
  },
});
