import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginate } from "../../../types/interfaces/common";
import { IAddress } from "../../../types/interfaces/customerSettings/address";
import apiData from "../../../utils/axiosGlobal";
import { baseQuery } from "../common";

export const AddressApi = createApi({
  reducerPath: "address",
  baseQuery: baseQuery,
  tagTypes: ["Address"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAddresses: builder.query<IApiResponse<IPaginate<IAddress[]>>, void>({
      query: () => "/get-addresses",
      providesTags: [{ type: "Address", id: "LIST" }],
    }),
    addAddress: builder.mutation<IAddress, Partial<IAddress>>({
      query: (body) => ({
        url: `/add-address`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Address"],
    }),
    updateAddress: builder.mutation<IAddress, Partial<IAddress>>({
      query(data) {
        return {
          url: `/edit-address/${data.id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Address", id: arg.id }, "Address"],
    }),
    DeleteAddress: builder.mutation<IAddress, Partial<IAddress>>({
      query(data) {
        return {
          url: `/delete-address/${data.id}`,
          method: "GET"
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Address", id: arg.id }, "Address"],
    }),
  }),
});

export const { 
    useGetAddressesQuery,
    useAddAddressMutation,
    useUpdateAddressMutation,
    useDeleteAddressMutation
} = AddressApi;


export const  createAddress = (token:string,body:{[key:string]:string|number}):any => {
  return apiData.post('/add-address',body,{
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "appication/json",
    },
  }).then(response => {
    return response
  }).catch((error) => {
    return error.response
  })
}


export const  getAddress1 = (token:any):any => {
  return apiData.get('/get-addresses',{
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "appication/json",
    },
  }).then(response => {
    return response
  }).catch((error) => {
    return error.response
  })
}

export const updateAdd = (data:IAddress,token:string):any => {
 return  apiData.post(`/edit-address/${data.id}`,data,{
    headers: {
       Authorization: `Bearer ${token}`,
      Accept: "appication/json",
    },
  }).then(response => {
    return response
  }).catch((error) => {
    return error.response
  })
}