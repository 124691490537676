import { useEffect } from "react";
import { matchRoutes, useLocation } from "react-router-dom"

export default function ScrollToTop() {
    const location = useLocation();    

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}


// const ScrollToTop = () => {
//   const route = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [route]);

//   return null;
// };

// export default ScrollToTop;