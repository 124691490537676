import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiResponse2, IPaginate, IPaginate2 } from "../../types/interfaces/common";
import { IAddress } from "../../types/interfaces/customerSettings/address";
import api from "../../utils/api";
import { baseQuery } from "./common";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ProductDetails } from "../../types/interfaces/productDetails";
import { ICurrency } from "../../types/interfaces/currency";
import apiData from "../../utils/axiosGlobal";




export const CurrencyApi = createApi({
  reducerPath: "currency",
  baseQuery: baseQuery,
  tagTypes: ["Currency"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    switchCurrency: builder.query<IApiResponse2<{}>, {name:string}>({
      query:  ({name}) => `/currency/switch/${name}`,
      providesTags: [{ type: "Currency", id: "LIST" }],
    }),
    getAllCurrency: builder.query<ICurrency[], void>({
      query:  () => `/get-all-currencies`,
      providesTags: [{ type: "Currency", id: "LIST" }],
    }),
    getCurrentCurrrency: builder.query<ICurrency, void>({
      query:  () => `/get-currency`,
      providesTags: [{ type: "Currency", id: "LIST" }],
    }), 
  }),
});


export const getCurrentCurrency =(token:string) => {
 return( apiData.get(`/get-currency`,{
    headers: {
      Authorization: token,
    },
}).then(response => {  
  return response
  }).catch(error => {
  return error
  })
 );
}

export const { 
    useSwitchCurrencyQuery,
    useGetAllCurrencyQuery,
    useGetCurrentCurrrencyQuery
} = CurrencyApi;

