import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from "./authRedux/customerSlice";
import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { AddressApi } from './services/customerSettings/address';
import { countriesApi } from './services/countries';
import { meApi } from './services/customerSettings/me';
import { ProductApi } from './services/product';
import { CartApi } from './services/Cart';
import { vendorApi } from './services/vendor';
import { CurrencyApi } from './services/currency';
import { MeasurementApi } from './services/customerSettings/measurement';
import { reviewApi } from './services/review';
import { favouriteApi } from './services/favourite';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (
        action?.payload?.status === 401 ||
        action?.payload?.data?.statusCode === 401 ||
        action?.meta?.baseQueryMeta?.response?.status === 401
      ) {
        localStorage.clear();
        if (!window.location?.pathname?.includes("/auth")) {
          window.location.href = "/auth";
        }
      }
    }
    return next(action);
  };

const customMiddleWares = [
  AddressApi.middleware,
  ProductApi.middleware,
  CartApi.middleware,
  meApi.middleware,
  CurrencyApi.middleware,
  MeasurementApi.middleware,
  vendorApi.middleware,
  countriesApi.middleware,
  reviewApi.middleware,
  favouriteApi.middleware,
  rtkQueryErrorLogger
];

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    [AddressApi.reducerPath]: AddressApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [CurrencyApi.reducerPath]: CurrencyApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    [MeasurementApi.reducerPath]: MeasurementApi.reducer,
    [CartApi.reducerPath]: CartApi.reducer,
    [meApi.reducerPath] : meApi.reducer,
    [reviewApi.reducerPath] : reviewApi.reducer,
    [favouriteApi.reducerPath] : favouriteApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(customMiddleWares)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
