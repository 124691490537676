import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IPaginateForParam } from "../../types/interfaces/common";
import { IAddress } from "../../types/interfaces/customerSettings/address";
import api from "../../utils/api";
import { baseQuery } from "./common";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { IStore, ProductDetails } from "../../types/interfaces/productDetails";
import { IVendor } from "../../types/interfaces/vendor";


export const vendorApi = createApi({
  reducerPath: "vendor",
  baseQuery: baseQuery,
  tagTypes: ["Vendor"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getVendorProducts: builder.query<IApiResponse<IPaginateForParam<IVendor[]>>,  {name:string,pagination: { page: number; num: number } }>({
      query:  ({ name,pagination: { page = 1, num = 12 } }) => `/get-store/${name}?page=${page}&num=${num}`,
      providesTags: [{ type: "Vendor", id: "LIST" }],
    })
  }),
});

export const { 
   useGetVendorProductsQuery
} = vendorApi;
