import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { Nav, Pagination as RPagination, Placeholder } from "rsuite";
import classes from "./luxury.module.css";
import classess from "../../LandingPage.module.css";


import CategoryCard from "../../../component/element/categoryCard";
import { ProductDetails } from "../../../types/interfaces/productDetails";
import { getAllBespoke, getAllProducts } from "../../../app/services/product";
import NewFooter from "../../../component/element/footer/NewFooter";
import { useNavigate } from "react-router-dom";
import {
  switchTab
} from "../../../app/authRedux/customerSlice";
import { useAppDispatch } from "../../../app/hooks";
import axios from "axios";
import { homeTab } from "../../../types/enums";

const { REACT_APP_BLOG_BASEURL } = process.env;

interface Post {
  id: number;
  name: string;
  slug: string;
  description: string;
  image: string;
  categories: { id: number; name: string; slug: string; description: string }[];
}

const Luxury = () => {
  const dispatch = useAppDispatch();

  const [allBespoke, setAllBespoke] = useState<ProductDetails[]>([]);
  const [ladingProduct, setLoadingProduct] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_BLOG_BASEURL}/posts`);
        setPosts(response.data?.data);
      } catch (err) {
        console.error("Error fetching blog posts:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const token = localStorage.getItem("token") || "";

  const handleChangePage = (dataKey: number) => {
    setCurrentPage(dataKey);
  };

  const fetchAllBespoke = async () => {
    try {
      let currentPage = 1;
      let allBespokeProducts:
        | any[]
        | ((prevState: ProductDetails[]) => ProductDetails[]) = [];

      // Check if bespoke products are already cached in local storage
      const cachedBespokeProducts = localStorage.getItem("allBespokeProducts");
      if (cachedBespokeProducts) {
        // setAllBespoke(JSON.parse(cachedBespokeProducts));
        // return;
        const res = await getAllBespoke(token, 1, 1);
        if (!res.data.error) {
          const products = res?.data?.data[0];
          if (products.total > JSON.parse(cachedBespokeProducts).length) {
            const res = await getAllProducts(token, 1, 8);
            if (!res?.data?.error) {
              const products = res?.data?.data[0]?.data;
              localStorage.setItem(
                "allBespokeProducts",
                JSON.stringify(products)
              );
              setAllBespoke(products);
              setLoadingProduct(false);
            }
          } else {
            setAllBespoke(JSON.parse(cachedBespokeProducts));
            setLoadingProduct(false);
            return;
          }
        }
      }

      // Continue fetching until all pages are fetched
      while (true) {
        const res = await getAllBespoke(token, currentPage, 100);

        if (!res.data.error) {
          const bespokeProducts = res?.data?.data[0]?.data;
          allBespokeProducts = [...allBespokeProducts, ...bespokeProducts];
          //@ts-ignore
          if (currentPage < res?.data?.data[0]?.last_page) {
            currentPage++;
          } else {
            break;
          }
        } else {
          break;
        }
      }

      // Cache fetched bespoke products in local storage
      localStorage.setItem(
        "allBespokeProducts",
        JSON.stringify(allBespokeProducts)
      );

      setAllBespoke(allBespokeProducts);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllBespoke();
  }, []);

  return (
    <>
      <div className={classes.banner}>
        <div className={classes.banner_main}>
          <div className={classes.banner_main_first}>
            <div className={classes.banner_main_first_content}>
              {/* <p>your new designs</p> */}
              <h1>Trybz Luxury</h1>
              <p>
                Where every piece tells a story and every item is a statement
                waiting to be made.
              </p>
              <p onClick={() => {}}>COMING SOON</p>
            </div>
            <a href="#luxury_collections">
              <div className={classes.scroll}>
              <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.55861 0.441386C1.2501 0.132871 0.7499 0.132871 0.441386 0.441386C0.132871 0.7499 0.132871 1.2501 0.441386 1.55861L1.55861 0.441386ZM9 9L8.44139 9.55861L9 10.1172L9.55861 9.55861L9 9ZM0.441386 1.55861L8.44139 9.55861L9.55861 8.44139L1.55861 0.441386L0.441386 1.55861ZM9.55861 9.55861L17.5586 1.55861L16.4414 0.441386L8.44139 8.44139L9.55861 9.55861Z" fill="white"></path></svg>
              <p>scroll</p>
              </div>
            </a>    
          </div>
          <div className={classes.banner_main_second}>
            <img
              // src={ladyInYellow}
              src={
                "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-landing-page.jpg"
              }
              alt="standing lady banner"
              height="100%"
              width="100%"
              role="presentation"
              className={classes.best_seller_image_main}
              // style={{ objectFit: "cover", objectPosition: "top" }}
            />
          </div>
        </div>
        <div className={classes.best_seller}>
          <p id="luxury_collections">
            Luxury Collections{" "}
            <span style={{ fontSize: "20px" }}>(Coming Soon)</span>
          </p>
          <div className={classes.best_seller_content}>
            <div className={classes.best_seller_content_first}>
              <img
                // src={ladyInSuit}
                src={
                  "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-all.jpg"
                }
                alt="lady in suit"
                // width="100%"
                className={classes.best_seller_image_main}
                // height="100%"
                role="presentation"
                loading="lazy"
              />
              {/* <p className={classes.best_seller_content_first_heading}>
                Jumpsuits
              </p> */}
              <p
                style={{ marginTop: "3rem" }}
                className={classes.best_seller_content_first_text}
              >
                Every item embodies elegance, reflecting timeless style and
                exquisite craftsmanship
              </p>
              {/* <button
                onClick={() => navigate("/category/jumpsuits")}
                className={`${classes.best_seller_content_first_button} ${classes.button_main}`}
              >
                Explore all
                <img src={cartItem} />
              </button> */}
            </div>
            <div className={classes.best_seller_content_second}>
              <div className={classes.best_seller_content_second_first}>
                <div className={classes.best_seller_content_second_first_diff}>
                  <img
                    // src={ladyInSuit}
                    src={
                      "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-2piece.jpg"
                    }
                    className={classes.best_seller_image_end_1}
                    alt="women group"
                    role="presentation"
                    loading="lazy"
                  />
                  <p className={classes.best_seller_content_first_heading}>
                    2 Piece
                  </p>
                  <p
                    className={classes.best_seller_content_first_text}
                    style={{ paddingBottom: "15px" }}
                  >
                    Summer take over with 2 piece outfits
                  </p>
                  {/* <button
                    onClick={() => navigate("/category/dresses")}
                    className={`${classes.best_seller_content_first_button} ${classes.button_sub}`}
                  >
                    Explore all
                    <img src={cartItem} />
                  </button> */}
                </div>

                <div className={classes.best_seller_content_second_first_2}>
                  <img
                    // src={womenGroup}
                    src={
                      "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-bou-and-kimono.jpg"
                    }
                    className={classes.best_seller_image}
                    alt="man in suit"
                    role="presentation"
                    loading="lazy"
                  />
                  <p className={classes.best_seller_content_first_heading}>
                    BouBou
                  </p>
                  <p className={classes.best_seller_content_first_text}>
                    Unique patterns and exquisite creations from the motherland
                  </p>
                  {/* <button
                    onClick={() => navigate("/category/suit-and-trouser")}
                    className={`${classes.best_seller_content_first_button} ${classes.button_sub}`}
                  >
                    See More
                    <img src={cartItem} />
                  </button> */}
                </div>
              </div>

              <div className={classes.best_seller_content_second_first}>
                <div className={classes.background_wrapper}>
                  <div className={classes.best_seller_image_change}> </div>
                  <div className={classes.best_Seller_cover}>
                    <h6
                      className={
                        classes.best_seller_content_first_heading_tranparent
                      }
                    >
                      Dresses
                    </h6>
                    <p className={classes.best_seller_content_first_text}>
                      Created by talented African designers embodying cultural
                      richness
                    </p>
                    {/* <button
                      onClick={() => navigate("/category/dresses")}
                      className={`${classes.best_seller_content_first_button} ${classes.button_sub}`}
                    >
                      Shop all
                      <img src={cartItem} />
                    </button> */}
                  </div>
                </div>

                <div
                  className={classes.best_seller_content_second_first_2}
                  style={{ paddingTop: "12px" }}
                >
                  <img
                    // src={ladyInGown}
                    src={
                      "https://trybz.fra1.cdn.digitaloceanspaces.com/landing/luxury/trybz-ca-luxury-traditional.jpg"
                    }
                    className={classes.best_seller_image_last}
                    alt="man in suit"
                    role="presentation"
                    loading="lazy"
                  />
                  <p className={classes.best_seller_content_first_heading}>
                    Traditional
                  </p>
                  <p className={classes.best_seller_content_first_text}>
                    Trad for your next occassion
                  </p>
                  {/* <button
                    onClick={() => navigate("/category/suit-and-trouser")}
                    className={`${classes.best_seller_content_first_button} ${classes.button_sub}`}
                  >
                    See More
                    <img src={cartItem} />
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className={classes.recommendation}>
          <h1 className="text-center">Recomended For You </h1>
          <div>
            <div className={classes.wrapper}>
              {
                <>
                  <div className={classess.contents}>
                    {allBespoke &&
                      allBespoke?.map((data: any, index: number) => (
                        <Fragment key={index}>
                          <CategoryCard data={data} />
                        </Fragment>
                      ))}
                  </div>
                  <div className="d-flex">
                    <RPagination
                      className="m-auto"
                      size="lg"
                      prev
                      next
                      first
                      last
                      ellipsis
                      maxButtons={5}
                      layout={["pager"]}
                      total={allBespoke?.length}
                      limitOptions={[12]}
                      limit={8}
                      activePage={currentPage}
                      onChangePage={handleChangePage}
                    />
                  </div>
                </>
              }
            </div>
          </div>
        </div> */}
      </div>

      <div className={classes.blog}>
        <h2>Blog</h2>
        <p>
          Discover the latest trends, style inspirations, and insider tips in
          our fashion-forward blog section. Unleash your creativity and stay
          ahead of the fashion curve with curated articles, expert interviews,
          and exclusive insights. Whether you're a trendsetter or a fashion
          novice, our blog is your go-to destination for all things chic. Dive
          into a world of fashion exploration.
        </p>
        {/* <div className={classes.blog_wrapper}>
          {loading ? (
            <Placeholder.Grid columns={2} rowMargin={20} active />
          ) : (
            posts.map((post) => (
              <div key={post.id} className={classes.blog_wrapper_content}>
                <div className={classes.content_img}>
                  <img
                    src={post.image}
                    alt={post.name}
                    width="100%"
                    height="100%"
                  />
                </div>
                <h6>{post.name}</h6>
                <p>{post.description}</p>
                <span>
                  <a
                    href={`https://blog.trybz.ca/${post.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </span>
              </div>
            ))
          )}
          
        </div> */}
        <div className={classes.blog_wrapper}>
          {loading ? (
            <Placeholder.Grid columns={2} rowMargin={20} active />
          ) : (
            posts.slice(0, 2).map((post) => (
              <div key={post.id} className={classes.blog_wrapper_content}>
                <div className={classes.content_img}>
                  <img
                    src={post.image}
                    alt={post.name}
                    width="100%"
                    height="100%"
                    role="presentation"
                    loading="lazy"
                  />
                </div>
                <h6>{post.name}</h6>
                <p>{post.description}</p>
                <span>
                  <a
                    href={`https://blog.trybz.ca/${post.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read more <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Luxury;
