import React, { ReactElement, useEffect, useState } from "react";
import Footer from "./element/footer/footer";
import TopNav from "./TopNav";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { IMe } from "../types/interfaces/customerSettings/me";
import { useGetUserDetailsQuery } from "../app/services/customerSettings/me";
import { useAppSelector } from "../app/hooks";
import { selectStateValues } from "../app/authRedux/customerSlice";
import NewFooter from "./element/footer/NewFooter";
import CustomNavigation from "./CustomNavigation";

const HomeLagosEx: React.FC<{ children: ReactElement }> = ({ children }) => {


  return (
    <>
      <main role="main"  className="col bg-white">
          <ToastContainer limit={1} />
          {children}
        <NewFooter />
      </main>
    </>
  );
};

export default HomeLagosEx;
