import React, { lazy, Suspense, useEffect, useState } from "react";
import logo from "./logo.svg";

import { Counter } from "./features/counter/Counter";
import "./App.css";
import TagManager from "react-gtm-module";
import { useLocation, useParams } from "react-router-dom";

// import HomeLayout from "./component/HomeLayout";
// import CreateAccount from "./pages/customer/auth/create";
// import { LandingPage, VendorHomepage } from "./pages";
import { Loading } from "./component/Loading";
import {
  Route,
  BrowserRouter,
  Router,
  Routes,
  useNavigate,
} from "react-router-dom";
// import PersonalProfileContainer from "./pages/customer/personalProfile";
// import Categories from "./pages/customer/category";
// import CartItem from "./pages/customer/cartItem/Cart";
// import ItemDetails from "./pages/customer/itemDetails";
// import Checkout from "./pages/customer/checkout/Checkout";
// import ProductContainer from "./pages/patner/accountProfile";
import ProtectedeRoutes from "./utils/ProtectedRoutes";
import axios from "axios";
import ScrollToTop from "./utils/ScrollToTop";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  getAuthentication,
  selectStateValues,
  timeoutFunc,
} from "./app/authRedux/customerSlice";
import IdleTimer from "./utils/idleTimer";
import apiData from "./utils/axiosGlobal";
import { ClearCurrentCookie } from "./utils/helpers";
import ErrorBoundary from "./component/ErrorBoundary";
import { toast, ToastContainer } from "react-toastify";
import HomePage from "./pages/HomePage";
import { log } from "console";
import HomePageLanding from "./pages/customer/homePageLanding/HomePageLanding";
import NewFooter from "./component/element/footer/NewFooter";
import HomeLagosEx from "./component/HomeLagosEx";

// import ProductContainer from "./pages/patner/accountprofile";
// import PersonalProfileContainer from "./pages/customer/personalProfile";
// import LandingPage from "./pages/LandingPage";

const HomeLayout = lazy(() => import("./component/HomeLayout"));
const LandingPage = lazy(() => import("./pages/LandingPage"));

const Basic = lazy(() => import("./pages/customer/basic/Basic"));

const VendorHomepage = lazy(() => import("./pages/patner/VendorHomepage"));
const PersonalProfileContainer = lazy(
  () => import("./pages/customer/personalProfile")
);
const CreateAccount = lazy(() => import("./pages/customer/auth/create"));
const Checkout = lazy(() => import("./pages/customer/checkout/Checkout"));
const CartItem = lazy(() => import("./pages/customer/cartItem/Cart"));
const Categories = lazy(() => import("./pages/customer/category"));
const SearchIndex = lazy(() => import("./pages/customer/search"));
const ItemDetails = lazy(() => import("./pages/customer/itemDetails"));
const ProductContainer = lazy(() => import("./pages/patner/accountprofile"));
const PatnerAuth = lazy(() => import("./pages/patner/auth/create"));
const PatnerLanding = lazy(() => import("./pages/patner/patnerLanding"));
const VerifyFailed = lazy(() => import("./pages/customer/verify/VerifyFailed"));
const VerifySuccess = lazy(
  () => import("./pages/customer/verify/VerifySuccess")
);
const AccountInfo = lazy(
  () => import("./pages/customer/personalProfile/account/accountInfo")
);
const AddressBook = lazy(
  () => import("./pages/customer/personalProfile/address/addressBook")
);
const Order = lazy(
  () => import("./pages/customer/personalProfile/orders/order")
);
const Favourite = lazy(
  () => import("./pages/customer/personalProfile/favourite/favourite")
);
const Measurement = lazy(
  () => import("./pages/customer/personalProfile/measurements/measurement")
);
const TermsAndCondition = lazy(
  () => import("./pages/customer/info/termsAndCondition/TermsAndCondition")
);

const SignupLagos = lazy(
  () => import("./pages/customer/lagosExp/Signup")
);
const ContactUs = lazy(
  () => import("./pages/customer/info/contactUs/ContactUs")
);
const AboutUs = lazy(() => import("./pages/customer/info/aboutUs/AboutUs"));
const ResetPassword = lazy(
  () => import("./pages/customer/auth/resetPassword/ResetPassword")
);
const NotFound = lazy(() => import("./pages/NotFound"));
const PatnerSuccess = lazy(
  () => import("./pages/customer/verify/patnerSuccess")
);

const VerifyEmail = lazy(
  () => import("./pages/customer/verifyEmail/verifyEmail")
);

function App() {
  //   declare const TagManager: {
  //     dataLayer: (dataLayerArgs: DataLayerArgs) => void;
  //     initialize: (tagManagerArgs: TagManagerArgs) => void;
  // };

  //   const tagManagerArgs = {
  //     gtmId: 'G-WXRFVBB38C'
  // }

  const tagManagerArgs = {
    gtmId: "G-WXRFVBB38C",
    dataLayerName: "PageDataLayer",
  };

  TagManager.initialize(tagManagerArgs);

  const currentPath = window.location.pathname;
  const token = localStorage.getItem("token") || "";

  const { timeout } = useAppSelector(selectStateValues);
  const dispatch = useAppDispatch();

  const [isTimeout, setIsTimeout] = useState(false);

  const handleLogout = () => {
    ClearCurrentCookie();
    apiData
      .get("/logout", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        localStorage.clear();
        dispatch(timeoutFunc({ payload: false }));
        dispatch(getAuthentication(false));
        if (currentPath.includes("/personalProfile")) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  useEffect(() => {
    const timer = new IdleTimer({
      // timeout: 10800, expires after 3 hours
      timeout: 3600,
      onTimeout: () => {
        // do something if expired on load
        // setIsTimeout(true);
        dispatch(timeoutFunc({ payload: false }));
        // handleLogout()
        // localStorage.removeItem("_expiredTime");
        localStorage.clear();
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
        // localStorage.removeItem("_expiredTime");
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [timeout == true]);

  const queryParam: any = useParams<{ [key: string]: string }>();
  const { pathname } = useLocation();

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          {/* <BrowserRouter> */}
          <ScrollToTop />
          {pathname == "/" ? (
            <Routes>
              <Route path="/" element={<HomePage />} />
            </Routes>
          ) : (
            <>
            {
            pathname == "/lagos-experience" ?
            
           <HomeLagosEx>
            <Routes>
                <Route path="/lagos-experience" element={<SignupLagos />} />
              </Routes>
           </HomeLagosEx>
             
            :
            <HomeLayout>
              <Routes>
                <Route element={<ProtectedeRoutes />}>
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/partner" element={<ProductContainer />} />
                  <Route
                    path="/personalProfile"
                    element={<PersonalProfileContainer />}
                  />

                  <Route
                    path="/personalProfile/account"
                    element={<AccountInfo />}
                  />
                  <Route
                    path="/personalProfile/addressBook"
                    element={<AddressBook />}
                  />
                  <Route path="/personalProfile/orders" element={<Order />} />
                  <Route
                    path="/personalProfile/favourites"
                    element={<Favourite />}
                  />
                  <Route
                    path="/personalProfile/measurements"
                    element={<Measurement />}
                  />
                </Route>
                <Route path="/home" element={<HomePageLanding />} />
                <Route path="/store/:name" element={<VendorHomepage />} />
                <Route
                  path="/password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route path="" element={<LandingPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/cart" element={<CartItem />} />
                <Route
                  path="/terms-and-condition"
                  element={<TermsAndCondition />}
                />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/auth" element={<CreateAccount />} />
                <Route path="/patnerAuth" element={<PatnerAuth />} />
                <Route path="/order-failed" element={<VerifyFailed />} />
                <Route path="/order-success" element={<VerifySuccess />} />
                <Route path="/partner-success" element={<PatnerSuccess />} />
                <Route path="/email/verify" element={<VerifyEmail />} />
                <Route path="/become-a-partner" element={<PatnerLanding />} />
                <Route path="/category/:category/*" element={<Categories />} />
                <Route path="/search/:id" element={<SearchIndex />} />
                <Route path="/item/:item" element={<ItemDetails />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </HomeLayout>
              }
            </>
          )}
          {/* </BrowserRouter> */}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
