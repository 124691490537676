import React, { useState } from "react";
import logo from "../assets/images/logoT.svg";
import "./sideNav.css";

const SideNav = ({ showSideNav, setShowSideNav }: any) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="sideNav">
      <div className="toplinks">
        <img src={logo} alt="logo" />
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="cursor-pointer"
        >
          {/* Conditional rendering based on hover state */}
          {hover ? (
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setShowSideNav(false);
              }}
            >
              {/* SVG path for close icon */}
              <path
                d="M19.071 6.343L17.657 4.929L12 10.586L6.343 4.929L4.929 6.343L10.586 12L4.929 17.657L6.343 19.071L12 13.414L17.657 19.071L19.071 17.657L13.414 12L19.071 6.343Z"
                fill="black"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.29289 11.7929C0.902369 12.1834 0.902369 12.8166 1.29289 13.2071L7.65685 19.5711C8.04738 19.9616 8.68054 19.9616 9.07107 19.5711C9.46159 19.1805 9.46159 18.5474 9.07107 18.1569L3.41421 12.5L9.07107 6.84314C9.46159 6.45262 9.46159 5.81946 9.07107 5.42893C8.68054 5.03841 8.04738 5.03841 7.65686 5.42893L1.29289 11.7929ZM22 11.5L2 11.5L2 13.5L22 13.5L22 11.5Z"
                fill="black"
              />
            </svg>
          )}
        </div>
      </div>
      <div className="links">
        <a href={""}>
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-8">
            HOME
          </span>
        </a>
        <a href="/home">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-[12px]">
            EVERYDAY TRYBZ
          </span>
        </a>
        <a href="/home">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-[12px]">
            TRYBZ LUXURY
          </span>
        </a>
        <a href="/category/clothing">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-8">
            MEN
          </span>
        </a>
        <a href="/category/clothing-1">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-8">
            WOMEN
          </span>
        </a>
        <a href="/category/accessories-2">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-8">
            KIDS
          </span>
        </a>
        <a href="/become-a-partner">
          <span className=" hover:text-base hover:font-semibold hover:underline hover:underline-offset-[12px]">
            BECOME A PARTNER
          </span>
        </a>
      </div>
    </div>
  );
};

export default SideNav;
