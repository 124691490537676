import React from "react";
import { Input, InputGroup } from "rsuite";
import classes from "./newFooter.module.css";
import fLogo from "../../../assets/icons/fLogo.svg";
import xLogo from "../../../assets/icons/xLogo.svg";
import igLogo from "../../../assets/icons/igLogo.svg";
import pinLogo from "../../../assets/icons/pinLogo.svg";
import logoT from "../../../assets/images/logoT.svg";

import { Link, useNavigate } from "react-router-dom";

const NewFooter = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.banner_wrapper} >
    <div className={classes.MainWrapper}>
      <div className={classes.MainWrapper_content}>
        <h1>Subscribe to newsletter</h1>
        <div className={classes.Input_container}>
          <input placeholder="Enter email" />
          <i
            
            className="fa-solid fa-arrow-right position-absolute"
          ></i>
        </div>
        <p>Get latest updates about new arrivals and discount deals</p>
        <div className="d-flex gap-5">
          <Link
            to="https://www.facebook.com/profile.php?id=61554153436078"
            target="_blank"
            className={classes.Lg}
          >
            <img src={fLogo} alt="facebook logo" />
          </Link>
          <Link
            to="https://www.twitter.com/trybz_ca"
            target="_blank"
            className={classes.Lg}
          >
            <img src={xLogo} alt="x logo" />
          </Link>
          <Link
            to="https://www.instagram.com/trybz.ca/"
            target="_blank"
            className={classes.Lg}
          >
            <img src={igLogo} alt="instagram logo" />
          </Link>
          <Link
            to="https://www.pinterest.com/trybzca"
            target="_blank"
            className={classes.Lg}
          >
            <img src={pinLogo} alt="pintrest logo" />
          </Link>
        </div>
      </div>

      <div className={classes.MainWrapper_content_2}>
        <div className={classes.MainWrapper_content_links}>
          <div>
            <p>
              <Link
                className="text-decoration-none text-dark"
                to="https://blog.trybz.ca"
              >
                Blog
              </Link>
            </p>
            <p>
              <Link className="text-decoration-none text-dark" to="/about-us">
                About Us
              </Link>
            </p>
            <p>
              <Link className="text-decoration-none text-dark" to="/contact-us">
                Contact
              </Link>
            </p>
            <p>
              <Link
                className="text-decoration-none text-dark"
                to="/terms-and-condition"
              >
                Terms &amp; Conditions
              </Link>
            </p>
          </div>

          <div className={classes.email_footer}>
            <h6 className="mb-4">Email</h6>
            <p>
              <a style={{ color: "inherit" }} href="mailto:info@trybz.ca">
                info@trybz.ca
              </a>
            </p>
          </div>
        </div>
        {/* <img src={logoT} width="30%" /> */}
      </div>
    </div>
    </div>
  );
};

export default NewFooter;
