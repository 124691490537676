import axios, { AxiosInstance } from 'axios';
// Create and configure the Axios instance
const { REACT_APP_BASEURL } = process.env;

const apiData: AxiosInstance = axios.create({
  baseURL: `${REACT_APP_BASEURL}`,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  },
});

// Set the default Authorization header as Bearer token
apiData.interceptors.request.use((config) => {
    const token = config.headers?.Authorization;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.withCredentials = true; 
    return config;
});

export default apiData;