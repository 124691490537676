import { createApi } from "@reduxjs/toolkit/query/react";
import { IApiResponse, IApiResponse2, IPaginate, IPaginate2 } from "../../types/interfaces/common";
import { IAddress } from "../../types/interfaces/customerSettings/address";
import api from "../../utils/api";
import { baseQuery } from "./common";
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ProductDetails } from "../../types/interfaces/productDetails";
import apiData from "../../utils/axiosGlobal";


export const ProductApi = createApi({
  reducerPath: "product",
  baseQuery: baseQuery,
  tagTypes: ["Product","Bespoke","Category"],
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getProduct: builder.query<IApiResponse2<IPaginate2<ProductDetails[]>>,  { pagination: { page: number; num: number } }>({
      query:  ({ pagination: { page = 1, num = 12 } }) => `/get-all-products?page=${page}&num=${num}`,
      providesTags: [{ type: "Product", id: "LIST" }],
    }),
    getBespoke: builder.query<IApiResponse2<IPaginate2<ProductDetails[]>>,  { pagination: { page: number; num: number } }>({
      query:  ({ pagination: { page = 1, num = 12 } }) => `/get-bespoke-products?collections=4&page=${page}&num=${num}`,
      providesTags: [{ type: "Bespoke", id: "LIST" }],
    }),
    getSingleProduct: builder.query<IApiResponse<IPaginate<ProductDetails>>, string>({
      query: (id) => `/get-product?slug=${id}`,
      providesTags: [{ type: "Product", id: "LIST" }],
    }),
    getSubProduct: builder.query<IApiResponse2<ProductDetails>, string>({
      query: (name:string) => `/get-category/${name}`,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getProductVariation: builder.query<IApiResponse<ProductDetails>,  { variation: { color: number; size: number },id:number }>({
      query: ({ variation: { color, size },id}) => `/get-product-variations/${id}?${color ? `attributes[color]=${color}&` : ''}attributes[size]=${size}`,
      // `/get-product-variations/${id}?attributes[color]=${color}&attributes[size]=${size}`,
      providesTags: [{ type: "Product", id: "LIST" }],
    })
  }),
});

export const { 
    useGetProductQuery,
    useGetSingleProductQuery,
    useGetBespokeQuery,
    useGetProductVariationQuery,
    useGetSubProductQuery
} = ProductApi;

const { REACT_APP_BASEURL } = process.env;


const config = (token:string) => {
 return {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`,
    } 
  }
}

interface ApiResponse {
  error:boolean
  data: [
    {
      data:ProductDetails[],
      path: string,
			per_page: number,
			prev_page_url: string|null,
			to: number,
			total: number
    }
  ];
}

export const getAllProducts = async(token:string,page:number,num:number|string):Promise<AxiosResponse<ApiResponse,any>> => {

  try{
    //:Promise<AxiosResponse<ApiResponse,any>>
    const res = await apiData.get(`${REACT_APP_BASEURL}/get-all-products?page=${page}&num=${num}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "appication/json",
      },
    })
    return res
  }catch(error:any){
    return error
  }

}

export const getSingleProduct = async(token:string,id:number):Promise<AxiosResponse<ApiResponse,any>> => {

  try{
    //:Promise<AxiosResponse<ApiResponse,any>>
    const res = await apiData.get(`${REACT_APP_BASEURL}/get-product?slug=${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "appication/json",
      },
    })
    return res
  }catch(error:any){
    return error
  }

}
export const getAllBespoke = async(token:string,page:number,num:number):Promise<AxiosResponse<ApiResponse,any>> => {
  try{
    const res = await apiData.get(`${REACT_APP_BASEURL}/get-bespoke-products?collections=4&page=${page}&num=${num}`,{
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "appication/json",
      },
    })
    return res
  }catch(error:any){
    return error
  }

}